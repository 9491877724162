import * as React from 'react';

import { getQuarter, getYear } from 'date-fns';

import { Portfolio } from 'javascript/models/portal/Portfolio';
import { Review } from 'javascript/models/portal/Review';

import { User } from 'javascript/models/User';

import Loading from 'components/shared/Loading';
import { humanizeNumber } from 'components/shared/Utils';

import CurrentUserContext from 'components/shared/context/CurrentUserContext';

import ClientPortalCard from 'components/portal/shared/ClientPortalCard';
import StyledWarning from 'components/shared/styledAlerts/StyledWarning';
import { portalDownloadsPath } from 'javascript/application/ts_routes';

import { createAlert } from 'components/shared/Utils';
import CsrfToken from 'components/shared/CsrfToken';

export default function reviews({ portfolioObject }) {
  const { client_type, id, client_id, reference } = portfolioObject;

  const [isLoading, setIsLoading] = React.useState(true);
  const [reviews, setReviews] = React.useState<Review[]>([]);
  const currentUser = React.useContext(CurrentUserContext) as User;

  React.useEffect(() => {
    getReviews().then(() => setIsLoading(false));
  }, []);

  async function getReviews() {
    const reviewScope = Review.selectExtra(['live_review_reports', 'bookkeeping_downloads'])
      .where({ status: ['sent'] })
      .order({ end_date: 'desc' });

    const { data } = await Portfolio.includes(['reviews']).merge({ reviews: reviewScope }).find(id);

    setReviews(data.reviews);
  }

  function ratingValue(ratingNumber) {
    if (ratingNumber) {
      return (
        <span
          className={`rating-box rating-box--small rating-box--dark-theme rating-box--${humanizeNumber(ratingNumber)}`}
        >
          {ratingNumber.toFixed(1)}
        </span>
      );
    }
    return <span className="rating-box rating-box--small rating-box--dark-theme">N/A</span>;
  }

  const noReport = <p className="text-grey text-small mar-v-0">Report unavailable</p>;

  const userCanDownloadReports = currentUser.hasPermission('review_report_downloader', client_id);
  const permissionWarning = StyledWarning({
    additionalClasses: ['mar-b-0'],
    message: 'You do not have permission to download review reports',
  });

  const reportsForReview = (review) => {
    if (userCanDownloadReports) {
      return review.liveReviewReports.map((reviewReport, index) => {
        const classNames = `${index !== review.liveReviewReports.length - 1 && 'mar-b-1'}`;

        return (
          <a
            key={reviewReport.id}
            href={reviewReport.path}
            target="_blank"
            className={`link link--white ${classNames}`}
          >
            <i className="fas fa-cloud-download-alt mar-r-1" />
            {reviewReport.name}
          </a>
        );
      });
    } else {
      return permissionWarning;
    }
  };

  const bookkeepingsDownload = (review) => {
    if (!review.bookkeepingDownloads || review.bookkeepingDownloads.length === 0) {
      return <p className="text-grey text-small mar-v-0">Bookkeeping unavailable</p>;
    }

    return (
      <div className="col-mc-2-6">
        <div className="text-small mar-v-0">
          <div className="frow frow--direction-column">
            {review.bookkeepingDownloads.map((download, index) => (
              <a
                key={download.id}
                href={download.path}
                target="_blank"
                rel="noopener noreferrer"
                className={`link link--white ${index < review.bookkeepingDownloads.length - 1 ? 'mar-b-1' : ''}`}
              >
                <i className="fas fa-cloud-download-alt mar-r-1" />
                {download.filename}
              </a>
            ))}
          </div>
        </div>
      </div>
    );
  };

  function renderReviews() {
    if (reviews.length < 1) {
      return <p className="text-white helper-text mar-t-3">This portfolio does not have any reviews yet.</p>;
    }

    const reviewCards = reviews
      .filter((review) => review.liveReviewReports.length > 0)
      .map((review) => {
        const reports = reportsForReview(review);

        return (
          <div className="col-mc-1-1" key={review.id}>
            <div className="comment">
              <div className="frow frow--gutters frow--items-center frow--nowrap">
                <div className="col-mc-1-6">
                  <p className="text-small text-bold text-white mar-v-0">
                    Q{getQuarter(review.endDate)} {getYear(review.endDate)}
                  </p>
                </div>
                {client_type !== 'consultancy' && (
                  <div className="col-mc-1-6">
                    <p className="text-small text-white mar-v-0">{ratingValue(review.rating)}</p>
                  </div>
                )}
                <div className="col-mc-2-6">
                  <div className="text-small mar-v-0">
                    <div className="frow frow--direction-column">
                      {review.liveReviewReports.length > 0 ? reports : noReport}
                    </div>
                  </div>
                </div>

                {bookkeepingsDownload(review)}
              </div>
            </div>
          </div>
        );
      });

    return (
      <div style={{ overflowY: 'auto', overflowX: 'hidden', height: '300px' }}>
        <div className="frow frow--gutters">{reviewCards}</div>
      </div>
    );
  }

  return (
    <div className="col-md-1-2">
      <ClientPortalCard title="Reviews">{isLoading ? <Loading /> : renderReviews()}</ClientPortalCard>
    </div>
  );
}

import * as React from 'react';

import ReactTooltip from 'react-tooltip';

import { platformPortfolioBenchmarkPath } from 'javascript/application/ts_routes';

import startCase from 'lodash.startcase';

import Loading from 'components/shared/Loading';
import { Portfolio } from 'javascript/models';

const LinkedBenchmarks = ({ portfolio }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [linkedBenchmarks, setLinkedBenchmarks] = React.useState([]);

  React.useEffect(() => {
    getLinkedBenchmarkInfo();
  }, []);

  const getLinkedBenchmarkInfo = async () => {
    try {
      const { data } = await Portfolio.select([]).selectExtra(['linked_benchmarks']).find(portfolio.id);
      setLinkedBenchmarks(data.linkedBenchmarks);
      setIsLoading(false);
    } catch (error) {
      // Handle any errors that occur during the API call
      setIsLoading(false);
    }
  };

  const formatBenchmarkType = (benchmarkType: string) => {
    return startCase(benchmarkType);
  };

  const renderAttributes = () =>
    linkedBenchmarks.map((benchmark, index) => {
      const primaryTag = <span className="text-brand-windows-blue">- Primary</span>;

      const secondaryTag = <span className="text-brand-windows-blue">- Secondary</span>;

      const tertiaryTag = <span className="text-brand-windows-blue">- Tertiary</span>;

      return (
        <dl className="definition-list" key={`${benchmark.id}-${index}`}>
          <dt>
            {formatBenchmarkType(benchmark.benchmark_type)} {benchmark.primary && primaryTag}
            {benchmark.secondary && secondaryTag}
            {benchmark.tertiary && tertiaryTag}
          </dt>
          <dd className="text-right" data-tip={benchmark.description} data-for="benchmark_descriptions">
            <a href={platformPortfolioBenchmarkPath(benchmark.id)}>{benchmark.name}</a>
          </dd>
        </dl>
      );
    });

  if (isLoading) {
    return (
      <div className="col-md-1-3">
        <Loading />
      </div>
    );
  }

  return (
    <div className="col-md-1-3">
      <div
        className="platform-content platform-content--padding-all platform-content--border-bottom-at-md
        h-100"
      >
        {renderAttributes()}
        <ReactTooltip effect="solid" id="benchmark_descriptions" />
      </div>
    </div>
  );
};

export default LinkedBenchmarks;

import * as colorFunctions from 'color';
import * as Color from 'color';

export function chartColors(
  labelName,
  theme,
  benchmarkTypes = { primary: null, secondary: null },
  index = 0,
  chartType = 'pie',
) {
  const {
    primary_color,
    secondary_color,
    tertiary_color,
    cash_color,
    fixed_income_color,
    equity_color,
    hedge_fund_color,
    other_color,
    gbp_color,
    usd_color,
    eur_color,
    jpy_color,
    hkd_color,
    chf_color,
    sgd_color,
    zar_color,
    cad_color,
    aud_color,
    nzd_color,
    sek_color,
    dkk_color,
    tbc_color,
    oth_color,
    secondary_color_one,
    secondary_color_two,
    secondary_color_three,
    secondary_color_four,
    secondary_color_five,
  } = theme;

  const themeColors = [
    secondary_color_one,
    secondary_color_two,
    secondary_color_three,
    secondary_color_four,
    secondary_color_five,
  ];

  const { primary, secondary } = benchmarkTypes;

  if (/MPI -/.test(labelName)) {
    if (/low/i.test(labelName)) {
      return secondary_color_three;
    }
    if (/medium/i.test(labelName)) {
      return secondary_color_four;
    }
    return secondary_color_five;
  }

  switch (labelName) {
    case 'Gross Balance':
      return primary_color;
    case 'Net Balance':
      return secondary_color;
    case 'portfolio':
    case 'Portfolio':
    case 'Consolidated Portfolio':
    case 'Consolidated portfolio':
    case 'Constituent Portfolio':
    case 'Constituent portfolio':
      return primary_color;
    case 'Manager Benchmark':
    case 'Manager benchmark':
      if (primary && primary === 'manager_benchmark') {
        return secondary_color;
      }
      if (secondary && secondary === 'manager_benchmark') {
        return tertiary_color;
      }
      return secondary_color_two;
    case 'Enhance Benchmark':
    case 'Enhance benchmark':
      if (primary && primary === 'enhance_benchmark') {
        return secondary_color;
      }
      if (secondary && secondary === 'enhance_benchmark') {
        return tertiary_color;
      }
      return secondary_color_one;
    case 'Target return':
    case 'Target Return':
      if (primary && primary === 'target_return') {
        return secondary_color;
      }
      if (secondary && secondary === 'target_return') {
        return tertiary_color;
      }
      return secondary_color_one;
    case 'Peer Group':
    case 'Peer group':
    case 'peer group':
      if (primary && primary === 'peer_group') {
        return secondary_color;
      }
      if (secondary && secondary === 'peer_group') {
        return tertiary_color;
      }
      return secondary_color_one;
    case 'Peer Group Low':
      return secondary_color_three;
    case 'Peer Group Med.':
      return secondary_color_four;
    case 'Peer Group High':
      return secondary_color_five;
    case 'Cash':
    case 'Cash Benchmark':
    case 'Cash benchmark':
    case 'cash':
      return cash_color;
    case 'Fixed Income':
    case 'Fixed income':
    case 'fixed income':
      return fixed_income_color;
    case 'Equity':
    case 'equity':
    case 'equities':
    case 'Equities':
      if (chartType !== 'pie') {
        return '#5b363f';
      }
      return equity_color;
    case 'Hedge Fund':
    case 'hedge_fund':
      return hedge_fund_color;
    case 'other':
    case 'Other':
      return other_color;
    case 'unclassified':
    case 'Unclassified':
      return '#5b363f';
    case 'gbp':
    case 'GBP':
      return gbp_color;
    case 'usd':
    case 'USD':
      return usd_color;
    case 'eur':
    case 'EUR':
      return eur_color;
    case 'jpy':
    case 'JPY':
      return jpy_color;
    case 'hkd':
    case 'HKD':
      return hkd_color;
    case 'chf':
    case 'CHF':
      return chf_color;
    case 'sgd':
    case 'SGD':
      return sgd_color;
    case 'zar':
    case 'ZAR':
      return zar_color;
    case 'cad':
    case 'CAD':
      return cad_color;
    case 'aud':
    case 'AUD':
      return aud_color;
    case 'nzd':
    case 'NZD':
      return nzd_color;
    case 'sek':
    case 'SEK':
      return sek_color;
    case 'dkk':
    case 'DKK':
      return dkk_color;
    case 'tbc':
    case 'TBC':
      return '#5b363f';
    case 'oth':
    case 'OTH':
      return oth_color;
    case 'Hedge':
      return gbp_color;
    case 'Cautious':
      return chf_color;
    case 'Balanced':
      return secondary_color;
    case 'Aggressive':
    case 'Growth':
      return secondary_color_four;
    default:
      if (index > 4) {
        const baseColor = themeColors[index % 5];
        return colorFunctions(baseColor)
          .rotate(15 * index)
          .string();
      }
      return themeColors[index];
  }
}

const modulateColour = (colour: string, phase: number) => {
  const lighten = phase % 2 === 0;
  const amount = 2 ** -Math.floor(phase / 2 + 0.5);

  const newColour = Color(colour);

  const operation = lighten ? 'lighten' : 'darken';

  return newColour[operation](amount).hex();
};

export function chartColorsV2() {
  const chartColourCache = {};

  return (labelName, theme, benchmarkTypes = { primary: null, secondary: null }, index = 0, chartType = 'pie') => {
    let returnVal = chartColors(labelName, theme, benchmarkTypes, index, chartType);

    if (chartColourCache.hasOwnProperty(returnVal)) {
      const thisPhase = chartColourCache[returnVal]++;
      returnVal = modulateColour(returnVal, thisPhase);
    } else {
      chartColourCache[returnVal] = 1;
    }

    return returnVal;
  };
}

export function selectableComparisons(portfolioObject) {
  const {
    cashBenchmark,
    enhanceBenchmark,
    equityBenchmark,
    managerBenchmark,
    peerGroupBenchmark,
    peerGroupLow,
    peerGroupMedium,
    peerGroupHigh,
  } = portfolioObject;
  const returnArray = [];
  if (enhanceBenchmark) returnArray.push({ label: 'Enhance Benchmark', value: 'enhance_benchmark' });
  if (managerBenchmark) returnArray.push({ label: 'Manager Benchmark', value: 'manager_benchmark' });
  if (peerGroupBenchmark) returnArray.push({ label: 'Relevant Peer Group', value: 'peer_group' });
  if (peerGroupLow) returnArray.push({ label: 'Peer Group Low', value: 'peer_group_low' });
  if (peerGroupMedium) returnArray.push({ label: 'Peer Group Med', value: 'peer_group_med' });
  if (peerGroupHigh) returnArray.push({ label: 'Peer Group High', value: 'peer_group_high' });
  if (cashBenchmark) returnArray.push({ label: 'Cash', value: 'cash' });
  if (equityBenchmark) returnArray.push({ label: 'Equity', value: 'equity' });

  return returnArray;
}

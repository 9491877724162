import { props } from 'ramda';

import mappedKeyCachingProxy from 'javascript/helpers/mappedKeyCachingProxy';
import extractSeries from 'javascript/helpers/chartData/extractSeries';

/*
 *   This function maps keys used in a benchmark chart legends to their type and finally to their line style information.
 */
const mapBenchmarkColourGenerator = (useTheme, labelTypeMap) => {
  const colourGen = useTheme('benchmark');

  const strokeDasharray = '3 3';
  const seriesTypeLineStyles = {
    portfolio: { stroke: colourGen('portfolio') },
    enhance_benchmark: { stroke: colourGen('enhance') },
    cash_benchmark: { stroke: colourGen('cash') },
    peer_group: { stroke: colourGen('peerGroup'), strokeDasharray },
    manager_benchmark: { stroke: colourGen('managers'), strokeDasharray },
    target_return: { stroke: colourGen('targetReturn') },
  };

  const proxyFunction = (_, property) => ({ stroke: colourGen(property) });

  return mappedKeyCachingProxy(labelTypeMap, proxyFunction, seriesTypeLineStyles);
};

/*
 * This is a function designed to determine the correct colour (based on the key & type) to be used for
 *.  benchmarks using a themeGenerator and series provided.
 *
 * @see extractSeries
 */
const dataBenchmarkColourGenerator = (useTheme, series) =>
  mapBenchmarkColourGenerator(useTheme, new Map(extractSeries(series).map(props(['label', 'type']))));

export { dataBenchmarkColourGenerator, mapBenchmarkColourGenerator };

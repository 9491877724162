import * as React from 'react';

import startCase from 'lodash.startcase';

import { HeadingThree } from 'components/shared/Typography';

export default function additionalInfo({ portfolioObject }) {
  const allBenchmarks = [
    'enhanceBenchmark',
    'managerBenchmark',
    'peerGroupBenchmark',
    'targetReturnBenchmark',
    'cashBenchmark',
    'equityBenchmark',
    'peerGroupLow',
    'peerGroupMedium',
    'peerGroupHigh',
  ];

  const requiredBenchmarks = allBenchmarks.filter((benchmark) => !!portfolioObject[benchmark]);

  const benchmarkInformation = requiredBenchmarks.map((benchmark) => {
    return (
      <div className="platform-content platform-content--border-bottom-grey pad-v-1" key={benchmark}>
        <div className="frow frow--gutters">
          <div className="col-mc-1-3">
            <span className="text-bold text-small">{startCase(benchmark)}</span>
          </div>
          <div className="col-mc-2-3">
            <span className="text-small">
              {portfolioObject[benchmark] ? portfolioObject[benchmark].description : 'Not applicable'}
            </span>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="modal__content">
      <HeadingThree text="Benchmark Information" additionalClasses="mar-b-1 text-grey" />
      {benchmarkInformation}
      <HeadingThree text="Rating Information" additionalClasses="mar-t-3 mar-b-1 text-grey" />
      <div className="platform-content platform-content--border-bottom-grey pad-v-1">
        <div className="frow frow--gutters">
          <div className="col-mc-1-3">
            <span className="text-bold text-small">5</span>
          </div>
          <div className="col-mc-2-3">
            <span className="text-small">
              The investment process has significantly enhanced the risk adjusted performance of the portfolio.
            </span>
          </div>
        </div>
      </div>
      <div className="platform-content platform-content--border-bottom-grey pad-v-1">
        <div className="frow frow--gutters">
          <div className="col-mc-1-3">
            <span className="text-bold text-small">4</span>
          </div>
          <div className="col-mc-2-3">
            <span className="text-small">
              The investment process has enhanced the risk adjusted performance of the portfolio.
            </span>
          </div>
        </div>
      </div>
      <div className="platform-content platform-content--border-bottom-grey pad-v-1">
        <div className="frow frow--gutters">
          <div className="col-mc-1-3">
            <span className="text-bold text-small">3</span>
          </div>
          <div className="col-mc-2-3">
            <span className="text-small">
              The investment process has produced reasonable risk adjusted performance.
            </span>
          </div>
        </div>
      </div>
      <div className="platform-content platform-content--border-bottom-grey pad-v-1">
        <div className="frow frow--gutters">
          <div className="col-mc-1-3">
            <span className="text-bold text-small">2</span>
          </div>
          <div className="col-mc-2-3">
            <span className="text-small">The investment process has produced poor risk adjusted performance.</span>
          </div>
        </div>
      </div>
      <div className="platform-content platform-content--border-bottom-grey pad-v-1">
        <div className="frow frow--gutters">
          <div className="col-mc-1-3">
            <span className="text-bold text-small">1</span>
          </div>
          <div className="col-mc-2-3">
            <span className="text-small">The investment process has produced very poor risk adjusted performance.</span>
          </div>
        </div>
      </div>
      <div className="platform-content platform-content--border-bottom-grey pad-v-1">
        <div className="frow frow--gutters">
          <div className="col-mc-1-3">
            <span className="text-bold text-small">Suspended</span>
          </div>
          <div className="col-mc-2-3">
            <span className="text-small">The rating is suspended.</span>
          </div>
        </div>
      </div>
      <div className="platform-content platform-content--border-bottom-grey pad-v-1">
        <div className="frow frow--gutters">
          <div className="col-mc-1-3">
            <span className="text-bold text-small">N/A</span>
          </div>
          <div className="col-mc-2-3">
            <span className="text-small">
              It is not possible to provide a rating based on risk adjusted performance as there is insufficient
              historic data.
            </span>
          </div>
        </div>
      </div>
      <div className="platform-content platform-content--border-bottom-grey pad-v-1">
        <div className="frow frow--gutters">
          <div className="col-mc-1-3">
            <span className="text-bold text-small">Benchmark Used</span>
          </div>
          <div className="col-mc-2-3">
            <span className="text-small">
              {portfolioObject.primaryBenchmark
                ? startCase(portfolioObject.primaryBenchmark.benchmark_type)
                : 'Not Applicable'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

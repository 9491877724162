import * as React from 'react';

import lodashStartcase from 'lodash.startcase';

import Row from './Row';
import Context from '../Context';

export default function presenter() {
  const context = React.useContext(Context);
  const { portfolio } = context.state;
  const { benchmarkInformation } = portfolio;

  const [primaryIndex, setPrimaryIndex] = React.useState(null);
  const [secondaryIndex, setSecondaryIndex] = React.useState(null);
  const [tertiaryIndex, setTertiaryIndex] = React.useState(null);

  const primaryChangeHandler = (index) => {
    if (index === primaryIndex) {
      setPrimaryIndex(null);
    } else {
      if (index === secondaryIndex) {
        setSecondaryIndex(null);
      }
      if (index === tertiaryIndex) {
        setTertiaryIndex(null);
      }
      setPrimaryIndex(index);
    }
  };

  function changeSecondaryIndex(index: number) {
    if (index === secondaryIndex) {
      setSecondaryIndex(null);
    } else {
      setSecondaryIndex(index);
    }
  }

  function changeTertiaryIndex(index: number) {
    if (index === tertiaryIndex) {
      setTertiaryIndex(null);
    } else {
      setTertiaryIndex(index);
    }
  }

  const benchmarkTypes = ['enhance_benchmark', 'manager_benchmark', 'peer_group', 'target_return'];

  const portfolioBenchmarks = benchmarkTypes.map((benchmarkType, index) => {
    const benchmarks = benchmarkInformation.filter((benchmark) => {
      return benchmark.benchmark_type === benchmarkType;
    });

    const uniqueIndex = benchmarkType
      .split('')
      .map((char, index) => {
        return benchmarkType.charCodeAt(index);
      })
      .reduce((a, b) => a + b, 0);

    const newBenchmark = (
      <Row
        key={index}
        {...{ benchmarkType, changeSecondaryIndex, changeTertiaryIndex, primaryIndex, secondaryIndex, tertiaryIndex }}
        portfoliosBenchmark={{}}
        index={uniqueIndex}
        changePrimaryIndex={primaryChangeHandler}
      />
    );

    // This should always be an array of one!
    const existingBenchmarks = benchmarks.map((portfoliosBenchmark, innerIndex) => {
      return (
        <Row
          key={portfoliosBenchmark.id}
          {...{
            benchmarkType,
            changeSecondaryIndex,
            changeTertiaryIndex,
            portfoliosBenchmark,
            primaryIndex,
            secondaryIndex,
            tertiaryIndex,
          }}
          index={(innerIndex + 1) * (index + 1)}
          changePrimaryIndex={primaryChangeHandler}
        />
      );
    });

    return (
      <div className="col-mc-1-1" key={index}>
        <label className="form__label">{lodashStartcase(benchmarkType)}</label>
        {benchmarks.length < 1 ? newBenchmark : existingBenchmarks}
      </div>
    );
  });

  return (
    <fieldset className="form__fieldset">
      <h4 className="form__fieldset-label">Benchmarks</h4>
      <div className="frow frow--gutters">{portfolioBenchmarks}</div>
    </fieldset>
  );
}
